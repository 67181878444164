export function formatAddress(address) {
  const straatMetHuisnummer = `${address.straat ? address.straat : ""} ${address.huisnummer ? address.huisnummer : ""}`;
  const busnummer = address.busnummer ? `bus ${address.busnummer}` : "";
  const straatMetNummer = [straatMetHuisnummer.trim(), busnummer.trim()]
    .join(" ")
    .trim();
  const gemeenteMetPostcode =
    `${address.postcode ? address.postcode : ""} ${address.gemeente ? address.gemeente : ""}`.trim();
  return [straatMetNummer, gemeenteMetPostcode]
    .filter(Boolean)
    .join(", ")
    .trim();
}
