import { createReduxStore } from "@skryv/core-react/src/core/store";

import apiMiddleware from "@skryv/core-react/src/store/api";

import {
  profile,
  currentMilestone,
  ocmwDossier,
  flConfiguration,
  dossierlistsWithAdditionalColumns,
} from "./reducers";

export default createReduxStore(
  {
    profile,
    currentMilestone,
    ocmwDossier,
    flConfiguration,
    dossierlistsWithAdditionalColumns,
  },
  undefined,
  [apiMiddleware],
);
