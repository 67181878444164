const defaultSize = 10;

const sortOptions = {
  "dossier.latestActivity": {
    order: "desc",
    nested: { path: "dossier" },
  },
};

function mapSearchTerms(searchTerms) {
  const wordRegex = /[A-zÀ-ü]+/;
  const searchTermItems = [];
  let index = 0;
  let isPreviousWord = true;

  searchTerms.forEach((term) => {
    if (term.match(wordRegex)) {
      if (isPreviousWord) {
        if (searchTermItems[index]) {
          searchTermItems[index] = `${searchTermItems[index]} ${term}`;
        } else {
          searchTermItems.push(term);
        }
      } else {
        searchTermItems.push(term);
        index++;
      }
      isPreviousWord = true;
    } else {
      searchTermItems.push(term);
      index++;
      isPreviousWord = false;
    }
  });

  return searchTermItems;
}

function fullQuery(searchTerm) {
  return [
    {
      query_string: {
        query: `*${searchTerm}*`,
        fields: [
          "dossier.label",
          "dossier.dossierDefinition.label",
          "document.dossierinfoKL.status.selectedOptionLabel.nl",
          "document.dossierinfoKL.datumStatus",
          "document.dossierinfoCA.status.selectedOptionLabel.nl",
          "document.dossierinfoCA.datumStatus",
          "document.kotlabel.status.selectedOptionLabel.nl",
          "document.kotlabel.datumStatus",
          "document.conformiteitsattest.status.selectedOptionLabel.nl",
          "document.conformiteitsattest.datumStatus",
        ],
      },
    },
    taskQuery(searchTerm),
    {
      query_string: {
        query: `*${searchTerm}*`,
        fields: [
          "document.aanvraagKotlabel.adresGebouw.straat",
          "document.aanvraagKotlabel.adresGebouw.gemeente",
          "document.aanvraagConformiteitsattest.adresGebouw.straat",
          "document.aanvraagConformiteitsattest.adresGebouw.gemeente",
          "document.kotlabel.adres.straat",
          "document.kotlabel.adres.gemeente",
          "document.conformiteitsattest.adres.straat",
          "document.conformiteitsattest.adres.gemeente",
        ],
      },
    },
    {
      query_string: {
        query: `${searchTerm}`,
        fields: [
          "document.aanvraagKotlabel.adresGebouw.huisnummer",
          "document.aanvraagKotlabel.adresGebouw.busnummer",
          "document.aanvraagKotlabel.adresGebouw.postcode",
          "document.aanvraagConformiteitsattest.adresGebouw.huisnummer",
          "document.aanvraagConformiteitsattest.adresGebouw.busnummer",
          "document.aanvraagConformiteitsattest.adresGebouw.postcode",
          "document.kotlabel.adres.huisnummer",
          "document.kotlabel.adres.busnummer",
          "document.kotlabel.adres.postcode",
          "document.conformiteitsattest.adres.huisnummer",
          "document.conformiteitsattest.adres.busnummer",
          "document.conformiteitsattest.adres.postcode",
        ],
      },
    },
  ];
}

function statusQuery(searchTerm) {
  return {
    query_string: {
      query: `*${searchTerm}*`,
      fields: [
        "document.dossierinfoKL.status.selectedOptionLabel.nl",
        "document.dossierinfoCA.status.selectedOptionLabel.nl",
        "document.kotlabel.status.selectedOptionLabel.nl",
        "document.conformiteitsattest.status.selectedOptionLabel.nl",
      ],
    },
  };
}

function taskQuery(searchTerm) {
  return {
    bool: {
      must: [
        {
          query_string: {
            query: true,
            fields: ["task.active"],
          },
        },
      ],
      should: [
        {
          query_string: {
            query: `*${searchTerm}*`,
            fields: ["task.name"],
          },
        },
      ],
    },
  };
}

function addressQuery(searchTerm) {
  return {
    bool: {
      must: [
        {
          query_string: {
            query: `*${searchTerm}*`,
            fields: [
              "document.aanvraagKotlabel.adresGebouw.straat",
              "document.aanvraagKotlabel.adresGebouw.gemeente",
              "document.aanvraagConformiteitsattest.adresGebouw.straat",
              "document.aanvraagConformiteitsattest.adresGebouw.gemeente",
              "document.kotlabel.adres.straat",
              "document.kotlabel.adres.gemeente",
              "document.conformiteitsattest.adres.straat",
              "document.conformiteitsattest.adres.gemeente",
            ],
          },
        },
      ],
      should: [
        {
          query_string: {
            query: `${searchTerm}`,
            fields: [
              "document.aanvraagKotlabel.adresGebouw.huisnummer",
              "document.aanvraagKotlabel.adresGebouw.busnummer",
              "document.aanvraagKotlabel.adresGebouw.postcode",
              "document.aanvraagConformiteitsattest.adresGebouw.huisnummer",
              "document.aanvraagConformiteitsattest.adresGebouw.busnummer",
              "document.aanvraagConformiteitsattest.adresGebouw.postcode",
              "document.kotlabel.adres.huisnummer",
              "document.kotlabel.adres.busnummer",
              "document.kotlabel.adres.postcode",
              "document.conformiteitsattest.adres.huisnummer",
              "document.conformiteitsattest.adres.busnummer",
              "document.conformiteitsattest.adres.postcode",
            ],
          },
        },
      ],
    },
  };
}

function mapSearchTermToQuery(searchTerm) {
  searchTerm = searchTerm.trim();
  searchTerm = searchTerm.replace("bus ", "");
  searchTerm = searchTerm.replace(",", "");
  const splitSearchTerms = searchTerm.split(" ");
  const query = [];

  if (splitSearchTerms.length > 1) {
    const searchTerms = mapSearchTerms(splitSearchTerms);
    const mappedSearchTerms = [];

    searchTerms.forEach((term) => {
      const wordRegex = /([A-zÀ-ü]+\\s)+/;
      const search = term.trim();

      if (search.match(wordRegex)) {
        mappedSearchTerms[0] = `${mappedSearchTerms[0] ? `${mappedSearchTerms[0]} OR ` : ""}${search}`;
      } else {
        mappedSearchTerms[1] = `${mappedSearchTerms[1] ? `${mappedSearchTerms[1]} OR ` : ""}${search}`;
      }
    });

    mappedSearchTerms.forEach((term) => {
      query.push(statusQuery(term));
      query.push(addressQuery(term));
      query.push(taskQuery(term));
    });
  } else {
    const dateRegex = /^[0-9]{2}-([0-9]{1,2}-?([0-9]{1,4})?)?$/g;
    const date = searchTerm.match(dateRegex);
    if (date) {
      searchTerm = searchTerm.split("-").reverse().join("-");
    }
    console.log(searchTerm);
    query.push(...fullQuery(searchTerm));
  }

  return query;
}

export function filterDashboardDossiersWithAdditionalColumns(
  searchTerm,
  pageNumber = 0,
  itemsPerPage = defaultSize,
  dossierTypes,
) {
  const query = createDossierSearchQuery(
    searchTerm,
    pageNumber,
    itemsPerPage,
    dossierTypes,
  );
  return fetchDossiersWithAdditionalColumns({ listKey: "DASHBOARD", query });
}

export function createDossierSearchQuery(
  searchTerm,
  pageNumber,
  itemsPerPage,
  dossierTypes = [],
) {
  return {
    query: {
      bool: {
        filter: [
          searchTerm && {
            bool: {
              should: [...mapSearchTermToQuery(searchTerm)],
            },
          },
          {
            query_string: {
              query: dossierTypes.length > 0 ? dossierTypes.join(" OR ") : "**",
              fields: ["dossier.dossierDefinition.key"],
            },
          },
          { bool: { should: [{ term: { type: "dossier" } }] } },
        ],
      },
    },
    from: pageNumber * itemsPerPage,
    size: itemsPerPage,
    sort: sortOptions,
  };
}

export function fetchDossiersWithAdditionalColumns({ query, listKey } = {}) {
  if (!query) {
    query = {
      from: 0,
      query: {
        bool: {
          filter: [{ bool: { should: [{ term: { type: "dossier" } }] } }],
        },
      },
      size: defaultSize,
      sort: {
        "dossier.latestActivity": {
          order: "desc",
          nested: { path: "dossier" },
        },
      },
    };
  }
  return {
    type: "FETCH_SEARCH_VLOK_DOSSIERS",
    listKey,
    api: {
      url: "api/vlok-dossiers/search",
      method: "POST",
      listKey,
      data: query,
    },
  };
}
