import React, { useContext, useEffect, useState } from "react";
import { map, includes, intersection, get } from "lodash";
import {
  dashboardPageWrapper,
  defaultProps,
  externalProps,
  internalProps,
} from "./flDashboardWrapper";
import contextType from "@skryv/core-react/src/services/contextTypes";
import authentication from "@skryv/core-react/src/services/authentication";
import moment from "moment";
import { Multiselect } from "@skryv/core-react-vo/src/components/base/Multiselect/Multiselect";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import dosdefKeys from "../constants/dossierDefinitionKeys";
import Button from "@skryv/core-react-vo/src/components/base/Button/Button";
import {
  HIDE_ALL_DOSSIER_BUTTONS,
  SHOW_DTL_DOSSIER_BUTTON,
  SHOW_GV_DOSSIER_BUTTON,
  SHOW_HL_DOSSIER_BUTTON,
  SHOW_HP_DOSSIER_BUTTON,
  SHOW_HP_WACHTTIJD_BUTTON,
  SHOW_HS_DOSSIER_BUTTON,
  SHOW_VGWVHR_DOSSIER_BUTTON,
  SHOW_VLOK_DOSSIER_BUTTONS,
} from "../constants/featureFlags";
import { dossierDefinitionsToCallToAction } from "./dashboard-helpers";
import CallToAction from "@skryv/core-react-vo/src/components/base/CallToAction/CallToAction";
import Skeleton from "react-loading-skeleton";
import Table from "@skryv/core-react-vo/src/components/base/Table/Table";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import Pager from "@skryv/core-react-vo/src/components/base/Pager/Pager";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import {
  isLBAanvrager,
  selectCurrentMilestoneWithDossierId,
  selectOcmwDossier,
  selectProfileTypeFromFlProfile,
} from "../store/selectors";
import {
  selectDossierListCount,
  selectExecutableTasksForDossier,
} from "@skryv/core-react/src/core/store/selectors/dossiers";
import { fetchFlProfile, fetchOcmwDossier } from "../store/actions";
import { fetchAllDossierDefinitions } from "@skryv/core-react/src/core/store/actions/dosdefs";
import {
  fetchCurrentDossierMilestone,
  fetchDossierExecutableTasks,
} from "@skryv/core-react/src/core/store/actions/dossiers";
import { connect } from "react-redux";
import router from "@skryv/core-react/src/services/router";
import { formatAddress } from "../helpers/address";

function DashboardPage(props) {
  const { fetchFlProfile, fetchOcmwDossier, fetchAllDossierDefinitions } =
    props;
  const context = useContext(contextType);
  const [userRoles, setUserRoles] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [dossierTypes, setDossierTypes] = useState(
    router.getDossierTypeSearchParameters(),
  );

  useEffect(() => {
    setIsLoadingPage(true);
    setIsLoadingUserProfile(true);

    Promise.all([
      fetchFlProfile(),
      fetchOcmwDossier(),
      fetchAllDossierDefinitions(),
    ]).finally(() => setIsLoadingUserProfile(false));

    authentication.profile().then((profile) => {
      const userRoles = get(profile, ["teamMembership", "roles"]) || [];
      setUserRoles(userRoles);
      setIsLoadingPage(false);
    });
  }, [fetchAllDossierDefinitions, fetchFlProfile, fetchOcmwDossier]);

  const extraColumnsDossiertypes = [
    "kotlabel",
    "conformiteitsattest",
    "aanvraag_kotlabel",
    "aanvraag_conformiteitsattest",
  ];
  const filteredDossierTypes = props.filteredDossierTypes;
  const overlappingDossierTypes = intersection(
    extraColumnsDossiertypes,
    filteredDossierTypes,
  ).length;
  const shouldAddExtraColumns =
    overlappingDossierTypes === filteredDossierTypes.length &&
    filteredDossierTypes.length > 0 &&
    filteredDossierTypes.length <= extraColumnsDossiertypes.length;

  const canCreateDossierOCMW = () => {
    return (
      userRoles.filter((group) => group.key === "fbu_ocmw_dossierbehandelaar")
        .length > 0
    );
  };

  const shouldShowNWDossierButton = () => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    return currentMonth < 5 && currentYear < 2025;
  };

  const canCreateOCMWDossiers = () => {
    return props.isOCMW && props.isLBAanvrager;
  };

  const mapDossierTypeOptions = () => {
    if (!props.allDosdefs) return [];

    const allDosdefs = props.allDosdefs;

    return Object.keys(allDosdefs).map((dosdefKey) => {
      const value = allDosdefs[dosdefKey].key;
      return {
        label: allDosdefs[dosdefKey].label,
        value: value,
        isSelected: includes(props.filteredDossierTypes, value),
        isDisabled: false,
      };
    });
  };

  const renderDossierTypeFilter = () => {
    const options = mapDossierTypeOptions();

    return (
      <>
        <div className="dossier-type-filter">
          <h2>Filter</h2>
          <Multiselect
            initialOptions={options}
            optionsChanged={(options) => {
              props.updateSearchParamsBasedOnSelection(options);
              setDossierTypes(options.map((option) => option.value));
            }}
            id="dossier-type-filter"
            placeholderText={"Select dossier type"}
          />
        </div>
      </>
    );
  };

  const renderOCMW = () => {
    const title = () => {
      return (
        <div>
          <Title level={1} title="Formulierenloket" />
        </div>
      );
    };

    const creatableDossiers = () => {
      const dossierInformation = {
        dosdefKey: dosdefKeys.FBU,
        buttonLabels: {
          active: "Start FBU Dossier",
        },
      };

      const createDossierButton = (dossierInformation) => {
        return {
          labels: dossierInformation.buttonLabels || {
            active: context.gettext("Steps to start this dossier"),
          },
          action: () => props.createNewDossier(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="button-group">
          {props.ocmwDossier && (
            <Button
              isSecondary={true}
              labels={{
                active: "OCMW Profiel",
              }}
              action={() => props.toDossier(props.ocmwDossier.id)}
            />
          )}
          {canCreateDossierOCMW() && (
            <Button
              labels={{
                active: createDossierButton(dossierInformation).labels.active,
              }}
              isBusy={props.creatingDossier[dossierInformation.dosdefKey]}
              action={createDossierButton(dossierInformation).action}
            />
          )}
          {canCreateOCMWDossiers() && shouldShowNWDossierButton() && (
            <Button
              labels={{
                active: "Start Noodwoningen",
              }}
              isBusy={props.creatingDossier[dosdefKeys.NW]}
              action={() => props.toDossierInformation(dosdefKeys.NW)}
            />
          )}
          {canCreateOCMWDossiers() && SHOW_DTL_DOSSIER_BUTTON && (
            <Button
              labels={{
                active: "Start Dak- en Thuislozen",
              }}
              isBusy={props.creatingDossier[dosdefKeys.DTL]}
              action={() => props.toDossierInformation(dosdefKeys.DTL)}
            />
          )}
        </div>
      );
    };

    return { title, creatableDossiers };
  };

  const renderDefault = () => {
    const title = () => {
      return null;
    };

    const creatableDossiers = () => {
      let whitelistedDossierDefinitions = [];

      if (SHOW_HP_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HP);
      }

      if (SHOW_HP_WACHTTIJD_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HPWT);
      }

      if (SHOW_HS_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HS);
      }

      if (SHOW_GV_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.GV);
      }

      if (SHOW_HL_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HL);
      }

      if (SHOW_VGWVHR_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.VGWVHR);
      }

      if (SHOW_VLOK_DOSSIER_BUTTONS) {
        whitelistedDossierDefinitions.push(
          dosdefKeys.AKL,
          dosdefKeys.ACA,
          dosdefKeys.CA,
          dosdefKeys.KL,
        );
      }

      const dossierDefinitions = dossierDefinitionsToCallToAction(
        props.dosdefs,
        whitelistedDossierDefinitions,
      );

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active:
              dossierInformation.startButtonLabel ||
              context.gettext("Steps to start this dossier"),
          },
          action: () =>
            props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(dossierDefinitions, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[dossierDefinitions.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    body={dossierInformation.description}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  };

  const renderLB = () => {
    const title = () => {
      return (
        <div className="dashboard-title vl-col--1-1">
          <Title
            level={1}
            title="Start hier je digitaal formulier van Wonen-Vlaanderen"
          />
        </div>
      );
    };

    const creatableDossiers = () => {
      const createDossierInformation = [];

      if (shouldShowNWDossierButton()) {
        createDossierInformation.push({
          dosdefKey: dosdefKeys.NW,
          subLabel: "Oproep noodwoningen",
          body: () => <p>Meer informatie</p>,
          buttonLabels: {
            active: "Selecteer",
          },
        });
      }

      if (SHOW_DTL_DOSSIER_BUTTON) {
        createDossierInformation.push({
          dosdefKey: dosdefKeys.DTL,
          subLabel: "Dak- en thuislozen",
          buttonLabels: {
            active: "Selecteer",
          },
        });
      }

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active: context.gettext("Steps to start this dossier"),
          },
          action: () =>
            props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(createDossierInformation, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[createDossierInformation.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    label={""}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  };

  const renderMyDossiers = () => {
    const ocmwDossierColumns = [
      {
        key: "label",
        text: "Dossier",
      },
      {
        key: "createdAt",
        text: "Created at",
        formatter: (cell) => {
          return moment(cell).format("DD-MM-YYYY");
        },
      },
      {
        key: "currentMilestone",
        text: "Status",
        formatter: (currentMilestone) => {
          return get(currentMilestone, "label");
        },
      },
      {
        key: "executableTasks",
        text: "Required actions",
      },
    ];

    let defaultDossierColumns = [
      {
        key: "label",
        text: "Dossier",
      },
      {
        key: "dossierDefinition.label",
        text: "Dossiertype",
      },
      {
        key: "createdAt",
        text: "Created at",
        formatter: (cell) => {
          return moment(cell).format("DD-MM-YYYY");
        },
      },
      {
        key: "currentMilestone",
        text: "Status",
        formatter: (currentMilestone) => {
          return get(currentMilestone, "label");
        },
      },
      {
        key: "executableTasks",
        text: "Required actions",
      },
    ];

    if (shouldAddExtraColumns) {
      defaultDossierColumns = [];
      defaultDossierColumns.push(
        ...[
          {
            key: "dossierLabel",
            text: "Dossier",
          },
          {
            key: "dossierDefinitionLabel",
            text: "Dossiertype",
          },
          {
            key: "adres",
            text: "Adres",
            formatter: (adres) => {
              if (!adres) return "";
              return formatAddress(adres);
            },
          },
          {
            key: "datumStatus",
            text: "Laatst gewijzigd",
            formatter: (datumStatus) => {
              return datumStatus
                ? moment(datumStatus).format("DD-MM-YYYY")
                : "";
            },
          },
          {
            key: "status",
            text: "Status",
            formatter: (status) => {
              return status;
            },
          },
          {
            key: "tasks",
            text: "Required actions",
          },
        ],
      );
    }

    const myDossierColumns =
      props.isCitizen || props.isCompany
        ? defaultDossierColumns
        : ocmwDossierColumns;
    const dossiersLabel =
      props.isCitizen || props.isCompany ? "Mijn dossiers" : "Dossiers";

    const formattedColumn = myDossierColumns.map((col) => {
      if (col.key === "executableTasks" || col.key === "tasks") {
        return Object.assign({}, col, {
          formatter: (tasks) => {
            if (tasks) {
              const lengthOfTasks = tasks.length;
              return (
                lengthOfTasks > 0 &&
                tasks.map((task) => {
                  return (
                    <span
                      className="vl-pill vl-pill--clickable d-block"
                      key={task.id}
                    >
                      <span
                        className="vl-icon vl-vi vl-vi-alert-circle"
                        aria-hidden="true"
                      ></span>
                      {task.name}
                    </span>
                  );
                })
              );
            }
          },
        });
      }
      if (col.key === "currentMilestone") {
        return Object.assign({}, col, {
          formatter: (currentMilestone) => {
            return <span>{currentMilestone.label}</span>;
          },
        });
      } else {
        return col;
      }
    });

    const loadingColumns = myDossierColumns.map((col) => {
      return Object.assign({}, col, {
        formatter: () => {
          return <Skeleton width={50} height={15} />;
        },
      });
    });

    const handleFilterTableAction = (e) => {
      setSearchQuery(e.target.value);
      if (shouldAddExtraColumns) {
        props.filterDossiersListWithAdditionalColumns(e.target.value);
      } else {
        props.filterDossiersList(e.target.value);
      }
    };

    return (
      <div className="dashboard-my-dossiers vl-col--1-1">
        <section className="vl-infoblock">
          <Title
            level={2}
            title={context.gettext(dossiersLabel)}
            iconClass="vl-vi-news"
          />
          <div className="vl-infoblock__content">
            <Table
              tableData={
                shouldAddExtraColumns
                  ? props.dossiersWithAdditionalColumns
                  : props.dossiers
              }
              tableColumns={
                props.loadingDossiers ? loadingColumns : formattedColumn
              }
              itemClickAction={(dossier) => props.toDossier(dossier.id)}
              filterTableAction={
                shouldAddExtraColumns || (!props.isCitizen && !props.isCompany)
                  ? handleFilterTableAction
                  : false
              }
              noDataText={
                props.loadingDossiers ? "" : context.gettext("No dossiers yet")
              }
            />
          </div>
        </section>
      </div>
    );
  };

  const renderContactInformation = () => {
    if (!props.contactInformation) return null;
    return (
      <div className="dashboard-contact vl-col--1-1">
        <section className="vl-infoblock">
          <Title
            level={2}
            title={context.gettext("Contact")}
            iconClass="vl-vi-chat"
          />
          <Contact contactInformation={props.contactInformation} />
        </section>
      </div>
    );
  };

  const renderCompany = () => {
    const title = () => {
      return null;
    };

    const creatableDossiers = () => {
      const whitelistedDossierDefinitions = [];

      if (SHOW_VLOK_DOSSIER_BUTTONS) {
        whitelistedDossierDefinitions.push(
          dosdefKeys.AKL,
          dosdefKeys.ACA,
          dosdefKeys.CA,
          dosdefKeys.KL,
        );
      }

      const dossierDefinitions = dossierDefinitionsToCallToAction(
        props.dosdefs,
        whitelistedDossierDefinitions,
      );

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active:
              dossierInformation.startButtonLabel ||
              context.gettext("Steps to start this dossier"),
          },
          action: () =>
            props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(dossierDefinitions, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[dossierDefinitions.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    body={dossierInformation.description}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  };

  const contentOCMW = (
    <div className="fl-dashboard-header-wrapper">
      {props.shouldShowTitle && renderOCMW().title()}
      {renderOCMW().creatableDossiers()}
    </div>
  );

  const contentCitizen = (
    <>
      {renderDefault().title()}
      {HIDE_ALL_DOSSIER_BUTTONS ? null : renderDefault().creatableDossiers()}
    </>
  );

  const contentLB = (
    <>
      {renderLB().title()}
      {renderLB().creatableDossiers()}
    </>
  );

  const contentCompany = (
    <>
      {renderCompany().title()}
      {renderCompany().creatableDossiers()}
    </>
  );

  const shouldHideMyDossiers = !props.isOCMW && props.dossiers.length === 0;
  const handleNextClick = (pageNumber) => {
    if (shouldAddExtraColumns) {
      props.filterDashboardDossiersWithAdditionalColumns(
        searchQuery,
        pageNumber,
        props.itemsPerPage,
        dossierTypes,
      );
    } else {
      props.searchDashboardDossiers(
        searchQuery,
        pageNumber,
        props.itemsPerPage,
      );
    }
  };

  return (
    <PageContent contentName="dashboard">
      {!isLoadingPage && !isLoadingUserProfile && props.isOCMW && contentOCMW}
      {props.shouldShowDossierTypeFilter && renderDossierTypeFilter()}
      {!shouldHideMyDossiers && renderMyDossiers()}
      {!shouldHideMyDossiers && (
        <Pager
          totalItemCount={props.totalDossiersCount}
          itemsPerPage={props.itemsPerPage}
          handleNextClick={(pageNumber) => handleNextClick(pageNumber)}
          handlePrevClick={(pageNumber) => handleNextClick(pageNumber)}
        />
      )}
      {!isLoadingPage &&
        !isLoadingUserProfile &&
        props.isCitizen &&
        contentCitizen}
      {!isLoadingPage &&
        !isLoadingUserProfile &&
        props.isCompany &&
        contentCompany}
      {!isLoadingPage && !isLoadingUserProfile && props.isLB && contentLB}
      {props.shouldShowContactInformation && renderContactInformation()}
    </PageContent>
  );
}

DashboardPage.propTypes = {
  ...externalProps,
  ...internalProps,
};

DashboardPage.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  let dossiers = [];
  if (ownProps.myDossiers) {
    [...ownProps.myDossiers].map((dossier) => {
      const currentMilestone = selectCurrentMilestoneWithDossierId(
        state,
        dossier.id,
      );
      const executableTasks = selectExecutableTasksForDossier(
        state,
        dossier.id,
      );
      dossiers.push({
        ...dossier,
        currentMilestone: {
          key: get(currentMilestone, ["key"]),
          label: get(currentMilestone, ["label"]),
        },
        executableTasks,
      });
    });
  }

  const extraColumnsDossiertypes = [
    "kotlabel",
    "conformiteitsattest",
    "aanvraag_kotlabel",
    "aanvraag_conformiteitsattest",
  ];
  const filteredDossierTypes = ownProps.filteredDossierTypes;
  const overlappingDossierTypes = intersection(
    extraColumnsDossiertypes,
    filteredDossierTypes,
  ).length;
  const shouldAddExtraColumns =
    overlappingDossierTypes === filteredDossierTypes.length &&
    filteredDossierTypes.length > 0 &&
    filteredDossierTypes.length <= extraColumnsDossiertypes.length;

  const ocmwDossier = selectOcmwDossier(state);
  const isCompany = selectProfileTypeFromFlProfile(state) === "company";
  const isOCMW = selectProfileTypeFromFlProfile(state) === "ocmw";
  const isLB = selectProfileTypeFromFlProfile(state) === "lb";
  const isCitizen = !isCompany && !isOCMW && !isLB;
  const totalDossiersCount = shouldAddExtraColumns
    ? get(state, [
        "dossierlistsWithAdditionalColumns",
        "dossiersWithAdditionalColumns",
        "total",
      ])
    : selectDossierListCount(state, "DASHBOARD");

  return {
    isCompany,
    isLBAanvrager: isLBAanvrager(state),
    isOCMW,
    isLB,
    isCitizen,
    ocmwDossier,
    dossiers,
    totalDossiersCount,
  };
};

const mapDispatchToProps = {
  fetchFlProfile,
  fetchOcmwDossier,
  fetchAllDossierDefinitions,
  fetchCurrentDossierMilestone,
  fetchDossierExecutableTasks,
};

export default dashboardPageWrapper(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage),
);
