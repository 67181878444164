import immutable from "seamless-immutable";
import get from "lodash/get";
import * as actions from "./actions";

export const profile = (state = immutable({}), action) => {
  if (action.type === "FETCH_FL_PROFILE/RESPONSE") {
    return immutable(action.response.data);
  }
  return state;
};

export const currentMilestone = (state = immutable({}), action) => {
  if (action.type === "FETCH_DOSSIER_CURRENT_MILESTONE/RESPONSE") {
    return state.merge({
      [action.dosId]: {
        key: get(action.response.data, ["key"]),
        label: get(action.response.data, ["status"]),
      },
    });
  }
  return state;
};

export const ocmwDossier = (state = immutable({}), action) => {
  if (action.type === "FETCH_OCMW_DOSSIER/RESPONSE") {
    return immutable(
      get(action.response.data, ["hits", "hits", 0, "_source", "dossier"]),
    );
  }
  return state;
};

export const flConfiguration = (
  state = immutable({ loading: false }),
  action,
) => {
  if (action.type === actions.FETCH_FL_APPLICATION_CONFIGURATION_RESPONSE) {
    return immutable({ loading: false, configuration: action.response.data });
  } else if (
    action.type === actions.FETCH_FL_APPLICATION_CONFIGURATION_REQUEST
  ) {
    return immutable({ loading: true });
  } else if (action.type === actions.FETCH_FL_APPLICATION_CONFIGURATION_ERROR) {
    return immutable({ loading: false });
  }
  return state;
};

export const dossierlistsWithAdditionalColumns = (
  state = immutable({ dossiersWithAdditionalColumns: {} }),
  action,
) => {
  switch (action.type) {
    case "FETCH_SEARCH_VLOK_DOSSIERS/RESPONSE": {
      const result = get(action, "response.data");
      return state.merge({ dossiersWithAdditionalColumns: result });
    }
    default:
      return state;
  }
};
